/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap');

html {
  scroll-behavior: smooth;
}
body{
  font-family: 'Lato', sans-serif;
}
.ngx-pagination .current,
.btn-info {
    background: #ffffff !important;
    color: #6c757d !important;
    border-radius: 5px !important;
}
.ngx-pagination a:hover, .ngx-pagination button:hover {
  background: #ffffff !important;
  border-radius: 5px;
}
.navbar-light .navbar-nav .nav-link {
  letter-spacing: 2px;
  font-weight: 500 !important;
}
/* .carousel-control-next-icon, .carousel-control-prev-icon {
  width: 10px;
} */
.carousel-indicators li {
  margin-top: -10% !important;
}
.nav-item a:hover {
  color: #fbfafa !important;
  background-color: #d1413d;
}

/* impian custom*/
.content-desktop {display: block;}
.content-mobile {display: none;}

/* @media screen and (max-width: 768px) {
  .content-desktop {display: block;}
  .content-mobile {display: none;}
} */
#imp-img {
  max-width: 1070px !important;
  padding: 20px;
}
#img-art {
  max-width: 80% !important;
}
#impian-section div > p > img {
  margin-top: 40px;
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0px 12px 26px -2px rgba(0,0,0,0.07);
  -webkit-box-shadow: 0px 12px 26px -2px rgba(0,0,0,0.07);
  -moz-box-shadow: 0px 12px 26px -2px rgba(0,0,0,0.07);
  padding: 50px;
}
.tab-plugin {
  margin-top: 40px;
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0px 12px 26px -2px rgba(0,0,0,0.07);
  -webkit-box-shadow: 0px 12px 26px -2px rgba(0,0,0,0.07);
  -moz-box-shadow: 0px 12px 26px -2px rgba(0,0,0,0.07);
  padding: 50px;
}

#impian-section > h2 {
  display: none !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  margin-top: 50% !important;
  height: 70px;
  width: 70px;
  /* outline: black; */
  background-size: 100%, 100%;
  border-radius: 50%;
  /* border: 1px solid black; */
  background-image: none;
  background-color: #56492d73;

}
.carousel-control-next-icon:after
{
  margin-top: 10px;
  content: '>';
  font-size: 40px;
  font-weight: bolder!important;
  color: #ffffff;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 40px;
  font-weight: bolder!important;
  color: #ffffff;

}
/* end */


